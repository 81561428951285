//@import 'fonts';
@import 'colors';

//$font-primary-ThinItalic: 'Calibre-ThinItalic';
//$font-primary-Thin: 'Calibre-Thin';
$font-primary-Sans: 'Raleway sans-serif';
$font-primary-BoldItalic: 'Raleway';
$font-primary-Regular: 'Raleway';
$font-primary-RegularItalic: 'Raleway';
$font-primary-Bold: 'Raleway';
//$font-Helvetica-Light: 'HelveticaNeue-Thin';
//$font-Didot-Bold: 'Didot-Bold';

$fs-larger: 36px;
$fs-mediumlarger: 32px;
$fs-largemedium: 30px;
$fs-mediumlarge: 28px;
$fs-large: 24px;
$fs-largreg: 20px;
$fs-reglarg: 18px;
$fs-regular: 16px;
$fs-small: 14px;
$fs-mdsmall: 12px;
$fs-smaller: 11px;

h1, h2, h3, h4, h5, h6 {
	font-family: $font-primary-Regular;
	color: $col-charcoal;
}

p {
	margin-top: 0;
	font-family: $font-primary-Regular;
	font-size: 18px;
	line-height: 24px;
	color: $col-charcoal;
}

strong {
	font-family: $font-primary-Regular;
}

.strong-highlight {
	font-family: $font-primary-Regular;
	text-decoration: underline;
}

.lk-title {
	font-size: $fs-large;
    font-family: $font-primary-Regular;
    margin: 25px auto 0;
    font-weight: normal;
    width: 100%;
    max-width: 300px;
    text-align: center;
    line-height: 1.08;
}

.lk-sub-title {
	font-size: 18px;
    font-family: $font-primary-Regular;
    margin: 0 auto 37px auto;
    font-weight: normal;
    width: 100%;
    max-width: 300px;
    text-align: center;
    line-height: 1.08;
}

.lk-para {
	font-size: 18px;
    font-family: $font-primary-Regular;
    font-weight: normal;
    width: 100%;
    max-width: 300px;
    margin: 25px auto 0;
    text-align: center;
    line-height: 1.11;
}

.lk-blockquote {
	font-family: $font-primary-Regular;
    font-size: $fs-larger;
    width: 100%;
    max-width: 300px;
    margin: 80px auto 50px;
    line-height: 1.06;
    position: relative;
    color: $col-grey-dark;
    text-align: center;

    cite {
    	font-family: $font-primary-Regular;
	    color: $col-charcoal;
	    font-size: 18px;
	    display: block;
	    margin-top: 30px;
	    font-style: normal;
	    text-align: center;
    }
}

.lk-assetsStyle-Dark {
	.lk-signIn-cta {
		color: $col-charcoal;
	}
}
