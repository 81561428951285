/*
 * Styles: Reset
 */

/*
 * Fix
 * Version 0.1.1
 * https://github.com/jaydenseric/Fix
 */

@import "typography";

* {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

body {
  margin: 0;
  /*font-family: sans-serif;*/
  font-family: $font-primary-Regular;
  line-height: 1;
  color: $col-dark-grey;
}

iframe {
  border: 0;
}

main {
  display: block;
}

ul,
ol {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

li {
  display: block;
}

dl {
  margin-top: 0;
  margin-bottom: 0;
}

dd {
  margin-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
}

blockquote {
  padding: 0;
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

sup {
  position: relative;
  top: -.5em;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

strong {
  font-weight: bold;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

button {
  padding: 0;
  margin: 0;
  overflow: visible;
  background: none;
  border: 0;
  text-align: inherit;
  text-transform: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}
