.testimoniale {
  border-bottom: 1px solid #C4B673FF;

  .client-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
