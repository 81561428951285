/* This stylesheet generated by Transfonter (https://transfonter.org) on March 1, 2018 4:17 PM */

@font-face {
  font-family: 'Raleway';
  src: url('./../fonts/Raleway-Light.eot');
  src: url('./../fonts/Raleway-Light.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Raleway-Light.woff2') format('woff2'),
  url('./../fonts/Raleway-Light.woff') format('woff'),
  url('./../fonts/Raleway-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./../fonts/Raleway-Regular.eot');
  src: url('./../fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Raleway-Regular.woff2') format('woff2'),
  url('./../fonts/Raleway-Regular.woff') format('woff'),
  url('./../fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./../fonts/Raleway-Medium.eot');
  src: url('./../fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
  url('./../fonts/Raleway-Medium.woff2') format('woff2'),
  url('./../fonts/Raleway-Medium.woff') format('woff'),
  url('./../fonts/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
